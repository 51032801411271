@font-face {
    font-family: 'Fira Sans Regular'; 
    src: url('../fonts/FiraSans-Regular.ttf');
}
@font-face {
    font-family: 'Fira Sans Condensed Regular'; 
    src: url('../fonts/FiraSansCondensed-Regular.ttf');
}
@font-face {
    font-family: 'Fira Sans Extra Condensed Regular'; 
    src: url('../fonts/FiraSansExtraCondensed-Regular.ttf');
}
@font-face {
    font-family: 'Fira Sans Light'; 
    src: url('../fonts/FiraSans-Light.ttf');
}
@font-face {
    font-family: 'Fira Sans Condensed Light'; 
    src: url('../fonts/FiraSansCondensed-Light.ttf');
}
@font-face {
    font-family: 'Fira Sans Extra Condensed Light'; 
    src: url('../fonts/FiraSansExtraCondensed-Light.ttf');
}
@font-face {
    font-family: 'Fira Sans Medium'; 
    src: url('../fonts/FiraSans-Medium.ttf');
}
@font-face {
    font-family: 'Fira Sans Condensed Medium'; 
    src: url('../fonts/FiraSansCondensed-Medium.ttf');
}
@font-face {
    font-family: 'Fira Sans Extra Condensed Medium'; 
    src: url('../fonts/FiraSansExtraCondensed-Medium.ttf');
}
@font-face {
    font-family: 'Fira Sans SemiBold'; 
    src: url('../fonts/FiraSans-SemiBold.ttf');
}
@font-face {
    font-family: 'Fira Sans Condensed SemiBold'; 
    src: url('../fonts/FiraSansCondensed-SemiBold.ttf');
}
@font-face {
    font-family: 'Fira Sans Extra Condensed SemiBold'; 
    src: url('../fonts/FiraSansExtraCondensed-SemiBold.ttf');
}
