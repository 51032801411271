/*
    Author: Stan Larin
    URL: www.stanlarin.com
*/
body {
    background-image: url('../img/bg.png');
}
body, p, a, pre, h1, h2, h3, h4, h5, h6 {
    font-family: $fira_sans--regular;
}

.sidebar {
    box-shadow: 3px 0 6px rgba($c-1, 0.2);
    background-color: #fff;
    @media screen and (min-width: 992px) {
        display: inline-block;
        width: 29.5%;
    }
    @media screen and (min-width: 1280px) {
        width: 24.5%;
    }
    
    .menu {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding: 25px 0;
        button, a {
            background: transparent;
            border: none;
            display: block;
            outline: none;
            &:hover, &:focus {
                border: none;
                outline: none;
            }
        }
    }
    .navigation-fixed {
        background-color: $c-0;
        position: absolute;
        z-index: 99;
        width: 100%;
        height: auto;
        display: none;
        .list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                a {
                    display: block;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    padding: 15px;
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid rgba($c-1, 0.2);
                    border-right: 4px solid transparent;
                    font-size: 14px;
                    text-decoration: none;
                    color: rgba($c-1, 0.6);
                    &:hover {
                        border-right-color: rgba($c-2, 0.8);
                        border-top-color: rgba($c-1, 0.8);
                        border-bottom-color: rgba($c-1, 0.8);
                        color:  rgba($c-1, 0.8);
                       /*&::after {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            height: 49px;
                            width: 2px;
                            background-color: $c-2;
                        }*/
                    }
                    &.active {
                        border-right-color: $c-2;
                        border-top-color: $c-1;
                        border-bottom-color: $c-1;
                        color:  $c-1;
                    }
                    img {
                        width: 100%;
                    }
                }
                &:first-child {
                    a {
                        border-top: 1px solid rgba($c-1, 0.2);
                        &.active {
                            border-top-color: $c-1;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 992px){
            display: none;
        }
    }
}

.navigation {
    margin-top: 25px;
    width: 100%;
    .list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            a {
                display: block;
                position: relative;
                height: 100%;
                width: 100%;
                padding: 15px;
                border-top: 1px solid transparent;
                border-bottom: 1px solid rgba($c-1, 0.2);
                border-right: 4px solid transparent;
                font-size: 14px;
                text-decoration: none;
                color: rgba($c-1, 0.6);
                &:hover {
                    border-right-color: rgba($c-2, 0.8);
                    border-top-color: rgba($c-1, 0.8);
                    border-bottom-color: rgba($c-1, 0.8);
                    color:  rgba($c-1, 0.8);
                }
                &.active {
                    border-right-color: $c-2;
                    border-top-color: $c-1;
                    border-bottom-color: $c-1;
                    color:  $c-1;
                }
                img {
                    width: 100%;
                }
            }
            &:first-child {
                a {
                    border-top: 1px solid rgba($c-1, 0.2);
                    &.active {
                        border-top-color: $c-1;
                    }
                }
            }
        }
    }
}

.menu {
    a {
        display: block;
        padding: 7.5px; 
    }
    button, a {
        background: transparent;
        border: none;
        display: block;
        outline: none;
        &:hover, &:focus {
            border: none;
            outline: none;
        }
    }
}

.conte {
    background-color: $c-0;
    box-shadow: 0 0 6px rgba($c-1, 0.2);
    margin: 30px auto;
    @media screen and (min-width: 1280px) {
        width: 60%;
    }
}

.cont {
    display: flex;
    align-items: stretch;
    height: 100%;
    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
    @media screen and (min-width: 992px) {
        flex-direction: row;
    }

}

.menu-fixed {
    position: relative;
    bottom: 1px;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 320px;
        background-color: $c-0;
        border-left: 4px solid $c-2;
        height: 100%;
        margin: 30px 0;
    }
}

.content {
    h2 {
        font-size: 18px;
        color: rgba($c-1, 0.8);
        letter-spacing: 0.2px;
        padding: 0 30px;
    }
    p {
        font-size: 16px;
        color: rgba($c-1, 0.8);
        letter-spacing: 0.2px;
        padding: 0 30px;
    }

    .heading {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin: 30px 0;
        h1 {
            max-width: 90%;
            min-width: 40%;
            //float: right;
            padding: 15px;
            margin: 0;
            top: 0;
            right: 0;
            background-color: $c-0;
            border-left: 4px solid $c-2;
            font-size: 24px;
            color: rgba($c-1, 0.9);
            
        }
    }

    .gallery {
        text-align: center;
        margin: 30px 0;
        padding: 0 30px;
        a {
            img {
                box-shadow: 0 0 6px rgba($c-1, 0.2);
                max-width: 100%;
            }
        }
    }

    @media screen and (min-width: 992px) {
        width: 70%;
    }
    @media screen and (min-width: 1280px) {
        width: 75%;
    }
}

.header, .footer {
    background-color: $c-1;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 120px;
    p {
        color: rgba($c-0, 0.8);
        font-size: 16px;
        font-family: $fira_sans--light;
        padding: 15px;
        text-align: center;
        margin: 0;
    }
    h1 {
        text-align: center;
        font-size: 32px;
        font-family: $fira_sans--light;
        color: rgba($c-0, 0.9);
    }
}

.footer {
    border-top: 4px solid $c-2;
}
.header {
    border-bottom: 4px solid $c-2;
}